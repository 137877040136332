const zeroFill = (number : string, width: number) => {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + ""; // siempre devuelve tipo cadena
  };
  
  const dateSpanish = (date: Date) => {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const dias_semana = [
      "Domingo",
      "Lunes",
      "martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const tiempoTranscurrido = date;
    const hoy = new Date(tiempoTranscurrido);
    const fecha =
      dias_semana[hoy.getDay()] +
      ", " +
      hoy.getDate() +
      " de " +
      meses[hoy.getMonth()] +
      " de " +
      hoy.getUTCFullYear();
    return fecha;
  };
  
 
  
  const formatDate_api = (date: Date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [year, month, day].join("-");
  };
  
  const dateSpanishShort = (date: Date) => {
    const meses = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ];
  
    const tiempoTranscurrido = date;
    const hoy = new Date(tiempoTranscurrido);
    const fecha =
      hoy.getDate() + " " + meses[hoy.getMonth()] + " " +hoy.getFullYear() + " " + hoy.getHours() + ":" + (hoy.getMinutes() < 10  ? '0' + hoy.getMinutes() : hoy.getMinutes())  + " h";
    return fecha;
  };

  const convertTime = (timestamp: number) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    let result = '';

    if (diffInSeconds >= 86400) { // 86400 seconds in a day
        return null;
    } else if (diffInSeconds >= 3600) { // 3600 seconds in an hour
        const diffInHours = Math.floor(diffInSeconds / 3600);
        result = `${diffInHours} horas`;
    } else if (diffInSeconds >= 60) { // 60 seconds in a minute
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        result = `${diffInMinutes} min`;
    } else if (diffInSeconds >= 60) { // 60 seconds limit
        return null;
    } else {
        result = `${diffInSeconds} seg`;
    }

    return result;
  }

  
  export { dateSpanish, formatDate_api, dateSpanishShort, zeroFill, convertTime };
  