import React, { FC } from 'react'
import s from './AdsSlot.module.css'
import Image from 'next/image';

interface AdsSlotTopProps {
    className?: string;
    }

const AdsSlotTop:FC<AdsSlotTopProps> = ({
    className
}) => {
  return (
    <div className={s.top_mobile}>
      <a href="https://www.westernunionperu.pe/cambiodemoneda?utm_source=ced&utm_content=banner" target="_blank" rel="noreferrer nofollow" >
        <Image
            src="/img/ads/cambioGanador.png"
            width={360}
            height={100}
            alt="ads"
        />
      </a>
    </div>
  )
}

export default AdsSlotTop